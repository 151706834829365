import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

import ButtonLoaded from "components/ButtonLoaded";
import { useState } from "react";
import WebhookField from "components/WebhookField";
import { useTranslation } from "react-i18next";

const DialogShop = ({
  handleClose,
  open,
  sendDomaine,
  loaded,
  success,
  domaine,
  setDomaine,
  setShopSelected,
  shopSelected,
  shops,
}) => {
  function isShopifyDomain(input) {
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9-]*\.myshopify\.com$/;
    return regex.test(input);
  }
  const URL_WEBHOOK = `${process.env.REACT_APP_API_URL}/shopify/webhook/orders_create`;
  const [ErrorDomaine, setErrorDomaine] = useState("");
  const [errorShop, setErorShop] = useState("");
  const [t] = useTranslation("global");
  const handleError = (domaine) => {
    let hasError = false;
    if (domaine === "") {
      setErrorDomaine("Vous devez ajouter un domaine de boutique");
      hasError = true;
    }
    if (shopSelected === null) {
      setErorShop("Ce champs boutique est requis");
      hasError = true;
    }
    if (!isShopifyDomain(domaine)) {
      setErrorDomaine("Invalid Shopify domain");
      hasError = true;
    }
    return hasError;
  };
  const CheckDomaine = () => {
    setErrorDomaine("");
    if (!handleError(domaine)) {
      sendDomaine();
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle color={"#01579b"} fontWeight={"600"}>
        {t("Se connecter à Shopify boutique")}
      </DialogTitle>

      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              m: "auto",
              width: "fit-content",
            }}
          >
            <TextField
              sx={{ minWidth: "70%" }}
              error={ErrorDomaine === "" ? false : true}
              helperText={ErrorDomaine === "" ? false : ErrorDomaine}
              onChange={(e) => setDomaine(e.target.value)}
              variant="outlined"
              placeholder="exemple.myshopify.com"
              className="auto-complete"
              startAdor
            />
            <Button
              sx={{ minWidth: "auto" }}
              // onClick={reverseCurrency}
              variant="contained"
              color="info"
            >
              <SwapHorizIcon />
            </Button>
            <Autocomplete
              options={shops || []}
              getOptionLabel={(option) => (option ? option.name_shop : "")}
              className="auto-complete"
              isOptionEqualToValue={(option, value) =>
                option.id_shop === value.id_shop
              }
              sx={{ minWidth: "70%" }}
              value={shopSelected}
              onChange={(event, newValue) => {
                setShopSelected(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  error={errorShop === "" ? false : true}
                  helperText={errorShop === "" ? false : errorShop}
                  {...params}
                  label={t("Boutiques")}
                  variant="outlined"
                />
              )}
            />
          </Box>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              paddingLeft: 1.5,
            }}
          >
            <WebhookField URL_WEBHOOK={URL_WEBHOOK} />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="inherit"
          size="small"
          style={{ textTransform: "none" }}
          onClick={handleClose}
        >
          {t("Annuler")}
        </Button>
        <ButtonLoaded
          success={success}
          loading={loaded}
          handleButtonClick={CheckDomaine}
          title={t("Valider")}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DialogShop;

import axios from "axios";
import { toast } from "react-toastify";
import sound from "../assets/Audio/success-sound.wav";
import { gapi } from "gapi-script";
import Swal from "sweetalert2";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Regular from "assets/Font/NotoNaskhArabic-Regular.ttf";
import Medium from "assets/Font/NotoNaskhArabic-Medium.ttf";
import SemiBold from "assets/Font/NotoNaskhArabic-SemiBold.ttf";
import Bold from "assets/Font/NotoNaskhArabic-Bold.ttf";
const apiURL = process.env.REACT_APP_API_URL;

export const getClientID = () => {
  return process.env.REACT_APP_SHEET_CLIENT_ID;
};

// branches
export const getBranchById = async (id) => {
  const resp = await axios.get(`${apiURL}/branchs/${id}`);
  return resp.data;
};
export const getBranchs = async () => {
  const resp = await axios.get(`${apiURL}/branchs`);
  return resp.data;
};
export const postBranch = async (data) => {
  const resp = await axios.post(`${apiURL}/branchs`, data);
  return resp;
};
export const putBranch = async (data, id) => {
  const resp = await axios.put(`${apiURL}/branchs/${id}`, data);
  return resp;
};
export const deleteBranch = async (ids) => {
  const resp = await axios.delete(`${apiURL}/branchs`, {
    data: { ids },
  });
  return resp;
};
// countries
export const getCountries = async () => {
  const resp = await axios.get(`${apiURL}/countries`);
  return resp.data;
};

export const getAllSalesPlatforme = async (branch = 1, paltforme) => {
  const res = await axios.get(
    `${apiURL}/shops/branch/${branch}/salePlatforme`,
    {
      params: {
        paltforme,
      },
    }
  );
  return res.data;
};

export const AddCustomer = async (name, address, phone, email, branch) => {
  const resp = await axios.post(`${apiURL}/customers/create`, {
    name: name,
    address: address,
    phone: phone,
    email: email,
    branch,
  });
  return resp;
};

export const UpdateCustomer = async (name, address, phone, email, id) => {
  const resp = await axios.put(`${apiURL}/customers/${id}/edit`, {
    name: name,
    address: address,
    phone: phone,
    email: email,
  });
  return resp;
};
export const DeleteCustomer = async (array, code) => {
  const resp = await axios.delete(`${apiURL}/customers/delete`, {
    data: { ids: array, id: code },
  });
  return resp;
};
export const DeleteUser = async (array, code) => {
  const resp = await axios.delete(`${apiURL}/user/delete`, {
    data: { ids: array, id: code },
  });
  return resp;
};
export const getUser = async (id) => {
  const resp = await axios.get(`${apiURL}/user/${id}`);
  return resp.data;
};
export const getUserWihoutAdmin = async (branch) => {
  const resp = await axios.get(`${apiURL}/users/typical/branch/${branch}`);
  return resp.data;
};

export const getUserAffectation = async () => {
  const resp = await axios.get(`${apiURL}/users/affectation`);
  return resp.data;
};
export const getAllCustomers = async (type, branch) => {
  const res = await axios.get(`${apiURL}/customers`, {
    params: {
      type,
      branch,
    },
  });
  return res.data;
};
export const getCountCustomers = async (branch, option) => {
  const res = await axios.get(
    `${apiURL}/customers/count/all/branch/${branch}`,
    {
      params: { option },
    }
  );
  return res.data;
};
export const getCustomer = async (id) => {
  const res = await axios.get(`${apiURL}/customers/${id}/edit`);
  return res.data;
};

//  Articles Besoins

export const getAllProvidersStatus = async (branch) => {
  const res = await axios.get(`${apiURL}/providers/status/branch/${branch}`);
  return res.data;
};
export const getProvider = async (id) => {
  const res = await axios.get(`${apiURL}/providers/${id}`);
  return res.data;
};
export const getProviderByName = async (name) => {
  const res = await axios.get(`${apiURL}/providers/name/${name}`);
  return res.data;
};

export const getAllOptions = async (branch) => {
  const res = await axios.get(`${apiURL}/options/branch/${branch}`);
  return res.data;
};
export const PostOption = async (name, type, branch) => {
  const res = await axios.post(`${apiURL}/options`, {
    name: name,
    type: type,
    branch,
  });
  return res;
};
export const PutOption = async (statut, id_option) => {
  const res = await axios.put(`${apiURL}/options/statut`, {
    statut: statut,
    id_option: id_option,
  });
  return res;
};
export const PutOptionValue = async (id, name, type, statut) => {
  const res = await axios.put(`${apiURL}/options`, {
    id: id,
    statut: statut,
    type: type,
    name: name,
  });
  return res;
};
export const PutTypeValue = async (id, type, statut) => {
  const res = await axios.put(`${apiURL}/types`, {
    id: id,
    statut: statut,
    type: type,
  });
  return res;
};
export const DeleteOption = async (array, code) => {
  const resp = await axios.delete(`${apiURL}/options`, {
    data: { ids: array, id: code },
  });
  return resp;
};
export const getAllTypes = async (branch) => {
  const res = await axios.get(`${apiURL}/types/branch/${branch}`);
  return res.data;
};
export const PostType = async (type, branch) => {
  const res = await axios.post(`${apiURL}/types`, {
    type: type,
    branch,
  });
  return res;
};
export const GetBilan = async (mode, date, date1, shop, branch) => {
  const resp = await axios.post(apiURL + "/charges/bilan", {
    mode,
    date,
    date1,
    shop,
    branch,
  });
  return resp.data;
};

export const GetBilanByTypeCharges = async (date, date1, shop, branch) => {
  const resp = await axios.post(apiURL + "/charges/bilan/type", {
    date: date,
    date1: date1,
    shop: shop,
    branch,
  });
  return resp.data;
};
export const GetBilanByTypeOrder = async (branch) => {
  const resp = await axios.get(`${apiURL}/orders/bilan/type/branch/${branch}`);
  return resp.data;
};
export const DeleteTypeCharge = async (array, code) => {
  const resp = await axios.delete(`${apiURL}/charges/type`, {
    data: { ids: array, id: code },
  });
  return resp;
};
export const DeleteCharge = async (array, code) => {
  const resp = await axios.delete(`${apiURL}/charges`, {
    data: { ids: array, id: code },
  });
  return resp;
};

export const PostTypeCharge = async (name) => {
  const res = await axios.post(`${apiURL}/charges/type`, {
    name: name,
  });
  return res;
};
export const PostCharge = async (
  typeSelected,
  shopsSelected,
  montant,
  comment,
  datePicker,
  id
) => {
  const res = await axios.post(`${apiURL}/charges`, {
    type: typeSelected,
    shop: shopsSelected,
    montant: montant,
    comment: comment,
    date: datePicker,
    user: id,
  });
  return res;
};
export const PutTypeCharge = async (name, id) => {
  const res = await axios.put(`${apiURL}/charges/type`, {
    id: id,
    name: name,
  });
  return res;
};
export const getAllTypesCharges = async () => {
  const res = await axios.get(`${apiURL}/charges/type`);
  return res.data;
};
export const getCharges = async (branch) => {
  const res = await axios.get(`${apiURL}/charges/branch/${branch}`);
  return res.data;
};
export const PutType = async (active, id_type) => {
  const res = await axios.put(`${apiURL}/types/statut`, {
    active: active,
    id_type: id_type,
  });
  return res;
};
export const DeleteType = async (array, code) => {
  const resp = await axios.delete(`${apiURL}/types`, {
    data: { ids: array, id: code },
  });
  return resp;
};
export const getAllProducts = async (branch) => {
  const res = await axios.get(`${apiURL}/products/branch/${branch}`);
  return res.data;
};
export const getAllProductsTable = async (branch) => {
  const res = await axios.get(`${apiURL}/products/all/branch/${branch}`);
  return res.data;
};
export const SynchroneProducts = async (branch) => {
  const res = await axios.get(`${apiURL}/products/synchrone/${branch}`);
  return res.data;
};
export const getAllProductsWithQtyInventory = async (branch) => {
  const res = await axios.get(
    `${apiURL}/products/inventory/qty/branch/${branch}`
  );
  return res.data;
};
export const getAllProductsByOrder = async (id) => {
  const res = await axios.get(`${apiURL}/products/commande/${id}`);
  return res.data;
};
export const VerifyProductInCmd = async (id) => {
  const res = await axios.get(`${apiURL}/products/excel/${id}`);
  return res.data;
};
export const VerifyProductsInCmdShopify = async (ids) => {
  const res = await axios.put(`${apiURL}/products/shopify`, {
    ids: ids,
  });
  return res.data;
};
export const VerifyCustomerInCmd = async (phone) => {
  const res = await axios.get(`${apiURL}/customers/excel/${phone}`);
  return res.data;
};

export const getAllProductsWithPriceAndQtyByOrder = async (id) => {
  const res = await axios.get(`${apiURL}/products/commande/list/${id}`);
  return res.data;
};

export const getAllOptionsOfProduct = async (ref) => {
  const resp = await axios(apiURL + "/products/options/" + ref);
  return resp.data;
};
export const DeleteProduct = async (array, code) => {
  const resp = await axios.delete(`${apiURL}/products/delete`, {
    data: { ids: array, id: code },
  });
  return resp;
};
export const UpdateProduct = async (
  ref,
  codeBar,
  name,
  prixVente,
  prixAchat,
  reduction,
  inventoryMin,
  comprtm,
  recevoir,
  description,
  lien,
  id_shops,
  id_fournisseur,
  id_brand
) => {
  const resp = await axios.put(`${apiURL}/products/edit`, {
    data: { ref, name },
  });
  return resp;
};
export const getProduct = async (code) => {
  const resp = await axios.get(`${apiURL}/products/${code}/edit`);
  return resp.data;
};
export const getOptionsOfProduct = async (code) => {
  const resp = await axios.get(`${apiURL}/products/${code}/options`);
  return resp.data;
};
export const getListOptions = async (code) => {
  const resp = await axios.get(`${apiURL}/products/${code}/options/list`);
  return resp.data;
};
export const PostTraficOrderQty = async (ids, motif, user, branch) => {
  const resp = await axios.post(`${apiURL}/inventory/trafic/order`, {
    ids: ids,
    motif: motif,
    user: user,
    branch,
  });
  return resp;
};
export const PostAffecterToEmployer = async (active, users) => {
  const resp = await axios.post(`${apiURL}/user/affecter`, {
    active: active,
    users: users,
  });
  return resp;
};

export const getAlertRuptureStock = async (branch) => {
  const res = await axios.get(`${apiURL}/inventory/rupture`, {
    params: { branch },
  });
  return res.data;
};
export const getAlertConfirStock = async (branch) => {
  const res = await axios.get(`${apiURL}/inventory/rupture/confirmation`, {
    params: { branch },
  });
  return res.data;
};
export const updateStateOFStatusProduits = async (id, active) => {
  const res = await axios.put(`${apiURL}/products/status/edit`, {
    id: id,
    active: active,
  });
  return res.data;
};

export const getTarifsOfProduct = async (code) => {
  const resp = await axios.get(`${apiURL}/products/${code}/tarifs`);
  return resp.data;
};
export const getLastIdListPriceProduct = async () => {
  const resp = await axios.get(`${apiURL}/products/prices/id`);
  return resp.data;
};

export const DeleteTrafics = async (array, code) => {
  const resp = await axios.delete(`${apiURL}/inventory/trafic/delete`, {
    data: { ids: array, id: code },
  });
  return resp;
};
export const getQteDisponibleOfProduct = async (ref) => {
  const resp = await axios.get(`${apiURL}/inventory/trafic/product/${ref}`);
  return resp.data;
};

// Trafic
export const getTrafics = async (branch) => {
  const resp = await axios.get(`${apiURL}/inventory/trafic/branch/${branch}`);
  return resp.data;
};
export const getTraficsByProduit = async (type, branch) => {
  const resp = await axios.get(`${apiURL}/inventory/trafic/products/${type}`, {
    params: { branch },
  });
  return resp.data;
};

export const getEnVente = async (type, branch) => {
  const resp = await axios.get(`${apiURL}/inventory/trafic/sale/${type}`, {
    params: { branch },
  });
  return resp.data;
};
// providers
export const getAllProviders = async (branch) => {
  const res = await axios.get(`${apiURL}/providers/branch/${branch}`);
  return res.data;
};
export const AddVendor = async (nameProvider, activeValue, branch) => {
  const res = await axios.post(`${apiURL}/providers/create`, {
    name: nameProvider,
    active: activeValue,
    branch,
  });
  return res.data;
};
export const UpdataProvider = async (id, nameProvider, activeValue) => {
  const res = await axios.put(`${apiURL}/providers`, {
    id: id,
    name: nameProvider,
    active: activeValue,
  });
  return res.data;
};
export const updateVendor = async (id, active) => {
  const res = await axios.put(`${apiURL}/providers/edite`, {
    id: id,
    active: active,
  });
  return res.data;
};
export const updateBrandStatus = async (id, active) => {
  const res = await axios.put(`${apiURL}/brands/status/edite`, {
    id: id,
    active: active,
  });
  return res.data;
};
export const DeleteVendor = async (array, code) => {
  const resp = await axios.delete(`${apiURL}/providers/delete`, {
    data: { ids: array, id: code },
  });
  return resp;
};

// brands
export const getAllBrands = async (branch) => {
  const res = await axios.get(`${apiURL}/brands/branch/${branch}`);
  return res.data;
};
export const getBrand = async (id) => {
  const res = await axios.get(`${apiURL}/brands/${id}`);
  return res.data;
};
export const getBrandByName = async (name) => {
  const res = await axios.get(`${apiURL}/brands/name/${name}`);
  return res.data;
};
export const AddBrand = async (nameBrand, toggle, branch) => {
  const res = await axios.post(`${apiURL}/brands/create`, {
    name: nameBrand,
    toggle,
    branch,
  });
  return res.data;
};
export const UpdateBrand = async (id, nameBrand, toggle) => {
  const res = await axios.put(`${apiURL}/brands`, {
    id,
    name: nameBrand,
    toggle,
  });
  return res;
};
export const updateBrand = async (id, active) => {
  const res = await axios.put(`${apiURL}/brands/edite`, {
    id: id,
    active: active,
  });
  return res.data;
};
export const DeleteBrand = async (array, code) => {
  const resp = await axios.delete(`${apiURL}/brands/delete`, {
    data: { ids: array, id: code },
  });
  return resp;
};

// delivreres
export const getAllPlatforms = async (branch) => {
  const res = await axios.get(`${apiURL}/platforms/branch/${branch}`);
  return res.data;
};
export const getAllDelivreres = async (branch) => {
  const res = await axios.get(`${apiURL}/delivreres/branch/${branch}`);
  return res.data;
};
export const getAllDelivreresByYalitec = async () => {
  const res = await axios.get(`${apiURL}/delivreres/yalitec`);
  return res.data;
};
export const getAllDelivreresTable = async () => {
  const res = await axios.get(`${apiURL}/delivreres/table`);
  return res.data;
};
export const getDelivrey = async (id) => {
  const res = await axios.get(`${apiURL}/delivrey/${id}`);
  return res.data;
};

export const getAllDelivreresShops = async (branch) => {
  const res = await axios.get(`${apiURL}/delivreres/shops/branch/${branch}`);
  return res.data;
};
export const getShopOfProduct = async (ref) => {
  const res = await axios.get(`${apiURL}/shops/product/${ref}`);
  return res.data;
};
export const DeleteDelivreres = async (array, code) => {
  const resp = await axios.delete(`${apiURL}/delivreres/delete`, {
    data: { ids: array, id: code },
  });
  return resp;
};
export const updateDelivrey = async (id, active) => {
  const res = await axios.put(`${apiURL}/delivreres/edite/status`, {
    id: id,
    active: active,
  });
  return res.data;
};
// Order
export const PostOrder = async (formData) => {
  const res = await axios.post(`${apiURL}/orders/create`, formData);
  return res;
};
export const PostOrderSheet = async (formData) => {
  const res = await axios.post(`${apiURL}/google/sheet/file`, formData);
  return res;
};
export const PostPickup = async (formData) => {
  // const res = await axios.post(`${apiURL}/pickup`, formData);
  // return res;
};
export const PostTraficPickup = async (ids, motif, user) => {
  const resp = await axios.post(`${apiURL}/pickup/trafic`, {
    ids: ids,
    motif: motif,
    user: user,
  });
  return resp;
};
export const getListPickup = async (type) => {
  // const res = await axios.get(`${apiURL}/pickup/list`);
  // return res.data;
};
export const UpdateQteOfProductPickup = async (id, qty) => {
  // const res = await axios.put(`${apiURL}/pickup/product/qty`, {
  //   id: id,
  //   qty: qty,
  // });
  // return res;
};
export const DeleteBonFromPickup = async (ids) => {
  // const res = await axios.put(`${apiURL}/pickup/form`, {
  //   ids: ids,
  // });
  // return res;
};
export const ChangeTypePickup = async (id, type, date) => {
  // const res = await axios.put(`${apiURL}/pickup/type`, {
  //   id: id,
  //   type: type,
  //   date,
  // });
  // return res;
};
export const getPickup = async (type) => {
  // const res = await axios.get(`${apiURL}/pickup/type/${type}`);
  // return res.data;
};
export const getPickupsByTypes = async () => {
  // const res = await axios.get(`${apiURL}/pickup/type/list/count`);
  // return res.data;
};

export const DeletePickup = async (arrayOfIds) => {
  // const res = await axios.delete(`${apiURL}/pickup`, {
  //   data: { ids: arrayOfIds },
  // });
  // return res;
};
export const PostHistoricOrder = async (action, user, desc, order, api) => {
  const res = await axios.post(`${apiURL}/orders/historic`, {
    action: action,
    user: user,
    desc: desc,
    order: order,
    api: api,
  });
  return res;
};
export const CheckOrderAPi = async (ids) => {
  const res = await axios.post(`${apiURL}/orders/check-cmdAPI`, {
    ids: ids,
  });
  return res.data;
};
export const UpdateQteOfProduct = async (id, qty) => {
  const res = await axios.put(`${apiURL}/orders/product/qty`, {
    id: id,
    qty: qty,
    // commande: commande,
    // montant: montant,
  });
  return res;
};

export const UpdatePriceOfProduct = async (id, price) => {
  const res = await axios.put(`${apiURL}/orders/product/price`, {
    id: id,
    price: price,
    // commande: commande,
    // montant: montant,
  });
  return res;
};
export const getAllHistoricOfOrder = async (commande) => {
  const res = await axios.get(`${apiURL}/orders/historic/${commande}`);
  return res.data;
};
export const getAllOrders = async (type, user, role, branch) => {
  const res = await axios.get(`${apiURL}/orders`, {
    params: {
      type,
      user,
      role,
      branch,
    },
  });
  return res.data;
};
export const getAllOrdersTypeDelivrey = async (status, user, role, branch) => {
  const res = await axios.get(
    `${apiURL}/orders/delivrey/list/${status}/user/${user}/${role}/branch/${branch}`
  );
  return res.data;
};
export const getAllOrdersWithYalitec = async (type, user) => {
  const res = await axios.get(`${apiURL}/orders/yalitec/${type}/user/${user}`);
  return res.data;
};
export const getOrderByRef = async (ref, type) => {
  const res = await axios.get(`${apiURL}/orders/ref/${ref}/type/${type}`);
  return res.data;
};
export const changeTypeOfOrderByref = async (ref, type, typeNew) => {
  const res = await axios.put(`${apiURL}/orders/ref/${ref}/type/${type}`, {
    typeNew: typeNew,
  });
  return res;
};
export const getAllOrdersApi = async (key, token, type) => {
  const res = await axios.post(apiURL + "/yalitec/orders", {
    key: key,
    token: token,
    type: type,
  });
  return res.data;
};
export const ValidateKeyYalitec = async (key, token) => {
  const res = await axios.post(`${apiURL}/yalitec/auth`, {
    key: key,
    token: token,
  });
  return res.data;
};
export const AuthMeta = async (accessToken, branch) => {
  const res = await axios.post(`${apiURL}/meta/auth`, {
    accessToken,
    branch,
  });
  return res.data;
};
export const refreshMeta = async (accessToken, branch) => {
  const res = await axios.post(`${apiURL}/meta/refresh`, {
    accessToken,
    branch,
  });
  return res.data;
};
export const getCurrencies = async () => {
  const res = await axios.get(`${apiURL}/currencies`);
  return res.data;
};
export const getTauxDevise = async (accountId) => {
  const res = await axios.get(`${apiURL}/currencies/accounts/${accountId}`);
  return res.data;
};
export const postCurrencies = async (id_account, de_id, vers_id, montant) => {
  const res = await axios.post(`${apiURL}/currencies`, {
    id_account,
    de_id,
    vers_id,
    montant,
  });
  return res.data;
};
export const postProduct = async (idcampaign, product_id) => {
  const res = await axios.post(`${apiURL}/meta/product`, {
    idcampaign,
    product_id,
  });
  return res;
};
export const getAllConnectionsMeta = async (branch) => {
  const res = await axios.get(`${apiURL}/meta/branch/${branch}`);
  return res.data;
};
export const getAllCampagns = async (accountId, since, till, statut) => {
  const res = await axios.get(`${apiURL}/meta/accounts/${accountId}/campagns`, {
    params: {
      since,
      till,
      statut,
    },
  });
  return res.data;
};
export const getAllAccounts = async (connectionId) => {
  const res = await axios.get(
    `${apiURL}/meta/connections/${connectionId}/accounts`
  );
  return res.data;
};
export const AuthYalidine = async (form) => {
  const res = await axios.post(`${apiURL}/yalidine/auth`, form);
  return res.data;
};
export const AuthZR = async (form) => {
  const res = await axios.post(`${apiURL}/setting/auth/zr`, form);
  return res.data;
};
export const ValidateTokenMaystro = async (form) => {
  const res = await axios.post(`${apiURL}/products/maystro`, form);

  // const res = await axios.get(`${Maystro_API}/product?page=1`, {
  //   headers: {
  //     Authorization: `Token ${token}`,
  //   },
  // });
  return res;
};
export const ValidateKeyNawris = async (data) => {
  const res = await axios.post(`${apiURL}/nawris`, data);
  return res;
};
export const getAllOrdersByShop = async (id) => {
  const res = await axios.get(`${apiURL}/orders/shop/${id}`);
  return res.data;
};
export const getOrdersByJourForMonthRecent = async (user) => {
  const res = await axios.get(`${apiURL}/orders/user/${user}/statistic/create`);
  return res.data;
};
export const GetCountOrderBytype = async (date1, date2, shop, branch) => {
  const resp = await axios.post(apiURL + "/orders/type/statistic", {
    date1,
    date2,
    shop,
    branch,
  });
  return resp.data;
};
export const GetCountOrderBytypeForStatistic = async (
  type,
  date1,
  date2,
  shop,
  branch
) => {
  const resp = await axios.post(apiURL + "/orders/type/livraison/statistic", {
    type,
    date1,
    date2,
    shop,
    branch,
  });
  return resp.data;
};

export const GetCountOrderBytypeForStatisticUser = async (
  date1,
  date2,
  shop,
  branch
) => {
  const resp = await axios.post(apiURL + "/orders/type/user/statistic", {
    date1,
    date2,
    shop,
    branch,
  });
  return resp.data;
};
export const getProductsByTypeCmdForStatistic = async (
  type,
  date1,
  date2,
  shop,
  branch
) => {
  const resp = await axios.post(apiURL + "/orders/type/products/statistic", {
    type,
    date1,
    date2,
    shop,
    branch,
  });
  return resp.data;
};
export const getOrdersByJourForTypeCmd = async (user) => {
  const res = await axios.get(`${apiURL}/orders/user/${user}/statistic/type`);
  return res.data;
};
export const getOrdersByTypeOrderAndUserForDateNow = async (user) => {
  const res = await axios.get(`${apiURL}/orders/user/${user}/type/now`);
  return res.data;
};
export const getOrdersByTypeOrderByFormForDateNow = async (user) => {
  const res = await axios.get(`${apiURL}/orders/user/${user}/type/forms/now`);
  return res.data;
};
export const getOrdersByTypeOrderByCAuser = async (user) => {
  const res = await axios.get(`${apiURL}/orders/user/${user}/type/CA/now`);
  return res.data;
};
export const getOrdersBymonths = async (branch) => {
  const res = await axios.get(`${apiURL}/orders/admin/months/branch/${branch}`);
  return res.data;
};
export const getOrdersByTypeCmd = async (user) => {
  const res = await axios.get(
    `${apiURL}/orders/user/${user}/statistic/type/list`
  );
  return res.data;
};
export const updateStatutUser = async (id, active) => {
  const res = await axios.put(`${apiURL}/user/edite`, {
    id: id,
    active: active,
  });
  return res.data;
};
export const getInvantaireOrders = async (type, branch) => {
  const res = await axios.get(`${apiURL}/orders/inventory/${type}`, {
    params: { branch },
  });
  return res.data;
};

export const getInvantaireOrdersByTypes = async (type, branch) => {
  const res = await axios.get(`${apiURL}/orders/inventory/${type}/types`, {
    params: { branch },
  });
  return res.data;
};
export const getCountsOrdersForCustomer = async (customerID) => {
  const res = await axios.get(`${apiURL}/orders/type/count/${customerID}`);
  return res.data;
};
export const getAllOrdersList = async () => {
  const res = await axios.get(`${apiURL}/orders/list`);
  return res.data;
};
export const getAllIDCommmandes = async (prefixs) => {
  const res = await axios.post(`${apiURL}/orders/prefixs`, {
    prefixs: prefixs,
  });
  return res.data;
};
export const VliderParExcel = async (orders, action, searchId) => {
  const resp = await axios.post(`${apiURL}/orders/excel/validation`, {
    orders,
    action,
    searchId,
  });
  return resp.data;
};
export const getAllOrdersListByTypeOrder = async (type) => {
  const res = await axios.get(`${apiURL}/orders/list/${type}`);
  return res.data;
};
export const PostAllOrdersListByTypeOrder = async (ids) => {
  const res = await axios.post(`${apiURL}/orders/list/order`, {
    ids: ids,
  });
  return res.data;
};
export const getAllOrdersTypesByCount = async (branch) => {
  const res = await axios.get(`${apiURL}/orders/type/list/branch/${branch}`);
  return res.data;
};
export const getOrdersStatus = async () => {
  const res = await axios.get(`${apiURL}/orders/status`);
  return res.data;
};
export const getCountOrdersByPeriode = async (value, date1, date2) => {
  const res = await axios.post(`${apiURL}/orders/count/admin/periode`, {
    value: value,
    date1: date1,
    date2: date2,
  });
  return res.data;
};
export const getCountOrders = async (branch, option) => {
  const res = await axios.get(`${apiURL}/orders/count/admin/branch/${branch}`, {
    params: { option },
  });
  return res.data;
};
export const getChargesMontant = async (branch, option) => {
  const res = await axios.get(`${apiURL}/charges/montant/branch/${branch}`, {
    params: { option },
  });
  return res.data;
};
export const getCAofOrders = async (branch, option) => {
  const res = await axios.get(`${apiURL}/orders/ca/admin/branch/${branch}`, {
    params: { option },
  });
  return res.data;
};
export const getAllOrdersTypes = async () => {
  const res = await axios.get(`${apiURL}/types/orders`);
  return res.data;
};
export const getAllOrdersTypesByCountOfUser = async (role, user, branch) => {
  const res = await axios.get(
    `${apiURL}/orders/type/list/${role}/user/${user}/branch/${branch}`
  );
  return res.data;
};
export const DeleteListCmd = async (idList) => {
  const res = await axios.delete(`${apiURL}/orders/list/delete/${idList}`);
  return res;
};

export const UpdateStatutListPickup = async (idList, type) => {
  // const res = await axios.put(`${apiURL}/pickup/list/edit`, {
  //   id: idList,
  //   type: type,
  // });
  // return res;
};
export const DeleteListPickup = async (idList) => {
  // const res = await axios.delete(`${apiURL}/pickup/list/delete/${idList}`);
  // return res;
};
export const ChangeTypeCmd = async (ids, type, backQty, user, branch) => {
  const res = await axios.put(`${apiURL}/orders/type/edit`, {
    ids: ids,
    type: type,
    backQty,
    user,
    branch,
  });
  return res;
};
export const UpdateOrdersMethode = async (methode, ids) => {
  const resp = await axios.put(`${apiURL}/orders/methode`, {
    methode,
    ids,
  });
  return resp.data;
};
export const DeleteOrders = async (arrayOfIds) => {
  const res = await axios.delete(`${apiURL}/orders/delete`, {
    data: { ids: arrayOfIds },
  });
  return res;
};
export const ChangeDelivreyOfOrder = async (orders, id_delivrey) => {
  const res = await axios.put(`${apiURL}/orders/delivrey/edit`, {
    orders: orders,
    id_delivrey: id_delivrey,
  });
  return res;
};
// Gestion des status
export const getStatus = async (type, branch) => {
  const res = await axios.get(`${apiURL}/status/${type}/branch/${branch}`);
  return res.data;
};
export const getProductsChild = async (sku) => {
  const res = await axios.get(`${apiURL}/products/childs/${sku}`);
  return res.data;
};
export const DeleteSkuParent = async (sku) => {
  const res = await axios.delete(`${apiURL}/products/childs/${sku}`);
  return res.data;
};
export const PostSkuParent = async (products, parent) => {
  const res = await axios.post(`${apiURL}/products/parent/${parent}/childs`, {
    products: JSON.stringify(products),
  });
  return res.data;
};

export const getCAByMonth = async (branch) => {
  const res = await axios.get(
    `${apiURL}/orders/ca/months/admin/branch/${branch}`
  );
  return res.data;
};
export const ChangeOrdersToDelivreyAPI = async (
  orders,
  delivrey,
  user,
  branch
) => {
  const resp = await axios.post(`${apiURL}/orders/api`, {
    orders,
    delivrey: { id: delivrey.id_delivrey, name: delivrey.name_delivrey },
    user,
    branch,
  });
  return resp;
};
export const getCountOrdersByCallUsers = async () => {
  const res = await axios.get(`${apiURL}/orders/call/users/admin`);
  return res.data;
};
export const getDisabledSynchrone = async (order) => {
  const res = await axios.get(`${apiURL}/orders/synchrone/${order}`);
  return res;
};
export const getDelevreeOrdersByMonth = async (branch) => {
  const res = await axios.get(
    `${apiURL}/orders/delevree/months/admin/branch/${branch}`
  );
  return res.data;
};
export const getHestoryOrderfromApi = async (
  order,
  id,
  token,
  setting_name
) => {
  let resp;
  if (setting_name === "Maystro") {
    resp = await axios.get(`/history_order/${order}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  } else if (setting_name === "Yalidine") {
    resp = await getHestoryOrderYalidine(id, token, order);
  }
  return resp.data;
};
export const getHestoryOrderYalidine = async (id, token, order) => {
  const resp = await axios.post(`${apiURL}/yalidine/hestory`, {
    id,
    token,
    order,
  });
  return resp;
};
export const updateStateOFStatusConfirmation = async (id, active) => {
  const res = await axios.put(`${apiURL}/status/confirmation/edite`, {
    id: id,
    active: active,
  });
  return res.data;
};

export const PostStatusConfirmation = async (formData) => {
  const res = await axios.post(`${apiURL}/status`, formData);
  return res.data;
};
export const UpdateStatusConfirmation = async (
  id,
  name,
  sms,
  toggle,
  notif
) => {
  const res = await axios.put(`${apiURL}/status/confirmation`, {
    id: id,
    name: name,
    sms: sms,
    toggle: toggle,
    notif: notif,
  });
  return res.data;
};
export const UpdateType = async (id, sms, notif) => {
  const res = await axios.put(`${apiURL}/orders/type`, {
    id: id,
    sms: sms,
    notif: notif,
  });
  return res.data;
};
export const DeleteStatus = async (array, code) => {
  const resp = await axios.delete(`${apiURL}/status/confirmation`, {
    data: { ids: array, id: code },
  });
  return resp;
};
export const AddTentative = async (motif, commande, user, comment) => {
  const res = await axios.post(`${apiURL}/status/confirmation/tentatives`, {
    motif: motif,
    comment: comment,
    commande: commande,
    user: user,
  });
  return res.data;
};
export const getAllTentative = async (id) => {
  const res = await axios.get(`${apiURL}/status/confirmation/tentatives/${id}`);
  return res.data;
};
// Gestion des shops
export const getAllShops = async (branch) => {
  const res = await axios.get(`${apiURL}/shops/branch/${branch}`);
  return res.data;
};
export const getSettingHaveActiveApi = async (branch) => {
  const res = await axios.get(`${apiURL}/shops/active-api/branch/${branch}`);
  return res.data;
};
export const getIdsOfShops = async (shops) => {
  const res = await axios.put(`${apiURL}/shops/ids`, {
    shops: shops,
  });
  return res.data;
};
export const getAllCategoriesYalitec = async () => {
  const res = await axios.get(`${apiURL}/shops/yalitec`);
  return res.data;
};
export const getShop = async (id) => {
  const res = await axios.get(`${apiURL}/shops/${id}`);
  return res.data;
};
export const getShopByName = async (name) => {
  const res = await axios.get(`${apiURL}/shops/sheet/${name}`);
  return res.data;
};

export const getAllShopOfAccessToken = async () => {
  const res = await axios.get(`${apiURL}/shops/access`);
  return res.data;
};
export const AddShop = async (data) => {
  const res = await axios.post(`${apiURL}/shop`, data);
  return res;
};

export const UpdateShopOfShop = async (id, domaine, checked_shopify) => {
  const res = await axios.put(`${apiURL}/shop/domaine`, {
    id: id,
    domaine: domaine,
    checked_shopify: checked_shopify,
  });
  return res;
};
export const UpdateCodeOfShop = async (code, domaine) => {
  const res = await axios.put(`${apiURL}/shop/code`, {
    code: code,
    domaine: domaine,
  });
  return res;
};
export const UpdateShop = async (id, name, address, prefix, branch) => {
  const res = await axios.put(`${apiURL}/shops`, {
    id: id,
    name,
    address,
    prefix,
  });
  return res;
};
export const UpdateShopApiElement = async (form) => {
  const res = await axios.put(`${apiURL}/shops/api`, form);
  return res;
};
export const DeleteShop = async (array, code) => {
  const resp = await axios.delete(`${apiURL}/shop/delete`, {
    data: { ids: array, id: code },
  });
  return resp;
};
export const DeleteSalePlatforme = async (settingId, platforme) => {
  const resp = await axios.delete(`${apiURL}/domaines/${settingId}`, {
    params: {
      platforme,
    },
  });
  return resp.data;
};
export const PutShopForSalePlatforme = async (id, shopId) => {
  const resp = await axios.put(`${apiURL}/domaines/${id}`, {
    shopId,
  });
  return resp;
};
// Login :
export const LoginUser = async (email, password, admin, branch) => {
  const resp = await axios.post(
    `${apiURL}/login`,
    {
      email,
      password,
      admin,
      branchId: branch,
    },
    { withCredentials: true }
  );
  return resp;
};
export const getUsers = async () => {
  const resp = await axios.get(`${apiURL}/users`);
  return resp.data;
};
// Gestion moderateur :
export const AddModerateur = async (
  name,
  phone,
  email,
  password,
  role,
  toggle,
  branch
) => {
  const resp = await axios.post(`${apiURL}/user`, {
    name,
    phone,
    email,
    password,
    role,
    toggle,
    branch,
  });
  return resp;
};
export const UpdateModerateur = async (
  id,
  name,
  phone,
  email,
  password,
  role,
  toggle,
  checkPassword,
  branch
) => {
  const resp = await axios.put(`${apiURL}/user`, {
    id,
    name,
    phone,
    email,
    password,
    role,
    toggle,
    checkPassword,
    branch,
  });
  return resp;
};
export const AddEmployeToCmd = async (orders, id_user) => {
  const resp = await axios.put(apiURL + "/orders/user", {
    orders: orders,
    id_user: id_user,
  });
  return resp;
};
// Gestion des roles :
export const getAllRole = async () => {
  const res = await axios.get(`${apiURL}/roles`);
  return res.data;
};
export const DeleteRole = async (array, code) => {
  const resp = await axios.delete(`${apiURL}/roles/delete`, {
    data: { ids: array, id: code },
  });
  return resp;
};
// Gestion permission
export const getPermissions = async () => {
  const resp = await axios.get(apiURL + "/permissions");
  return resp.data;
};
export const getRolesOfUser = async (id) => {
  const resp = await axios.get(apiURL + `/roles/${id}`);
  return resp.data;
};
export const AddRole = async (name, permissions) => {
  const resp = await axios.post(apiURL + "/roles", {
    name: name,
    permissions: permissions,
  });
  return resp;
};
export const UpdateRole = async (id, name, permissions) => {
  const resp = await axios.put(apiURL + "/roles", {
    id: id,
    name: name,
    permissions: permissions,
  });
  return resp;
};
export const notifySuccess = (txt) => {
  return toast.success(txt, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
    theme: "light",
  });
};
export const transformData = (data) => {
  const transformedData = [];

  data.forEach((item) => {
    const existingItem = transformedData.find(
      (entry) => entry.id_delivrey === item.id_delivrey
    );
    const newItem = {
      platform_id: item.platform_id,
      cost_delivrey: item.cost_delivrey,
      cost_return: item.cost_return,
      cost_pickup: item.cost_pickup,
      cost_stopDesk: item.cost_stopDesk,
    };

    if (existingItem) {
      existingItem.array.push(newItem);
    } else {
      transformedData.push({
        id_delivrey: item.id_delivrey,
        name_delivrey: item.name_delivrey,
        platform_id: item.platform_id,
        array: [newItem],
      });
    }
  });

  return transformedData;
};
export const transformDataShops = (data) => {
  const transformedData = [];

  data.forEach((item) => {
    const existingItem = transformedData.find(
      (entry) => entry.id_shop === item.id_shop
    );
    const newItem = {
      cost_delivrey: item.cost_delivrey,
      cost_stopDesk: item.cost_stopDesk,
    };

    if (existingItem) {
      existingItem.array.push(newItem);
    } else {
      transformedData.push({
        id_shop: item.id_shop,
        name_shop: item.name_shop,
        prefix: item.prefix,
        array: [newItem],
      });
    }
  });

  return transformedData;
};
// Gestion de form
export const CreateBon = async (forms, objet, type, status) => {
  const resp = await axios.post(apiURL + "/form", {
    forms: forms,
    objet: objet,
    type: type,
    status: status,
  });
  return resp;
};
export const CreateBonPickup = async (pickups, objet, type, status) => {
  // const resp = await axios.post(apiURL + "/form/pickup", {
  //   pickups: pickups,
  //   objet: objet,
  //   type: type,
  //   status: status,
  // });
  // return resp;
};
export const getAllform = async (type, branch) => {
  const resp = await axios.get(`${apiURL}/forms/${type}/branch/${branch}`);
  return resp.data;
};
export const getAllformPickup = async () => {
  // const resp = await axios.get(`${apiURL}/pickup/forms`);
  // return resp.data;
};
export const getAllOrderByForm = async (idform) => {
  const resp = await axios.get(`${apiURL}/orders/form/${idform}`);
  return resp.data;
};
export const UpdateOrdersYalitec = async (type, user, role, branch) => {
  const resp = await axios.get(
    `${apiURL}/orders/yalitec/update/${type}/${user}/${role}/branch/${branch}`
  );
  return resp.data;
};
export const UpdateOrdersMaystro = async (type, auth) => {
  const resp = await axios.post(`${apiURL}/orders/synchrone`, {
    branch: auth.branch,
    role: auth.role,
    user: auth.idUser,
    type,
  });
  return resp.data;
};

export const UpdateOrdersShopify = async (branch) => {
  const resp = await axios.get(`${apiURL}/api/shopify/orders/branch/${branch}`);
  return resp.data;
};
export const UpdateOrdersYoucan = async (branch) => {
  const resp = await axios.get(
    `${apiURL}/orders/youcan/update/branch/${branch}`
  );
  return resp.data;
};
export const ManageOperationBackInBon = async (idsOrder, idsForm, type) => {
  const resp = await axios.put(apiURL + "/orders/form", {
    idsOrder: idsOrder,
    idsForm: idsForm,
    type: type,
  });
  return resp.status;
};

export const ConfirmeBon = async (idsBons, statut, date) => {
  const resp = await axios.put(apiURL + "/form", {
    idsBons: idsBons,
    statut: statut,
    date: date,
  });
  return resp;
};
export const PostOrderYalitecInDB = async (orders, user) => {
  const resp = await axios.post(apiURL + "/orders/yalitec", {
    orders: orders,
    user: user,
  });
  return resp;
};
export const checkLogin = async () => {
  const resp = await axios.get(apiURL + "/login", { withCredentials: true });
  return resp;
};
export const Logout = async () => {
  const resp = await axios.get(apiURL + "/logout", {
    withCredentials: true,
  });
  return resp;
};
export const refreshLogin = async () => {
  const resp = await axios.post(apiURL + "/login/refresh");
  return resp;
};
export function getCurrentDateTime() {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, "0"); // Get day with leading zero
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Get month with leading zero
  const year = now.getFullYear();
  const hours = String(now.getHours()).padStart(2, "0"); // Get hours with leading zero
  const minutes = String(now.getMinutes()).padStart(2, "0"); // Get minutes with leading zero

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}
export const ChangeFormStringToDate = (date) => {
  const dateObj = new Date(date);
  // Obtenez les composants de la date (année, mois, jour)
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Ajoute un zéro initial si nécessaire
  const day = String(dateObj.getDate()).padStart(2, "0"); // Ajoute un zéro initial si nécessairehour
  const hour = String(dateObj.getHours()).padStart(2, "0"); // Ajoute un zéro initial si nécessaire
  const minutes = String(dateObj.getMinutes()).padStart(2, "0"); // Ajoute un zéro initial si nécessaire

  // Créez la chaîne de date au format "2023-08-28"
  return `${year}-${month}-${day} ${hour}:${minutes}`;
};

export const ToastStatus = {
  position: "top-right",
  autoClose: 3000, // Notification auto-closes after 3 seconds
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  progress: undefined,
  // Add a sound effect when the notification is shown
  onOpen: () => playNotificationSound(),
};
const playNotificationSound = () => {
  const audio = new Audio(sound); // Replace with the path to your sound file
  audio.play();
};
export const AuthWithGoogle = async () => {
  const resp = await axios.get(apiURL + "/auth/google", {
    withCredentials: true,
  });
  return resp.data;
};
export const getSheetFiles = async (form) => {
  const resp = await axios.post(apiURL + "/google/sheet/files", form);
  return resp.data;
};
export const GetSpreadsheetId = (setFiles, setSuccessImport) => {
  let objet = {};
  gapi.client.drive.files
    .list({
      q: "mimeType='application/vnd.google-apps.spreadsheet'",
    })
    .then((response) => {
      const items = response.result.files;
      if (items && items.length > 0) {
        // Vous pouvez accéder au spreadsheetId du premier fichier ici.
        // const spreadsheetId = items[0].id;
        setFiles(items);
        setSuccessImport(true);
      } else {
        Swal.fire({
          title: "Attention",
          position: "center",
          icon: "error",
          text: "Aucun fichier Google Sheets trouvé.",
          showConfirmButton: true,
          confirmButtonColor: "#2399baff",
          timer: 4500,
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        title: "Attention",
        position: "center",
        icon: "error",
        text: "Erreur lors de la recherche des fichiers Google Sheets",
        showConfirmButton: true,
        confirmButtonColor: "#2399baff",
        timer: 4500,
      });
    });
  return objet;
};

// Gestion des drafts

export const postBrouillon = async (form) => {
  const resp = await axios.post(apiURL + "/draft", form);
  return resp;
};
export const postOrdersExcel = async (form) => {
  const resp = await axios.post(apiURL + "/orders/excel", form);
  return resp;
};
export const PostApiyBrouillon = async (form) => {
  const resp = await axios.post(apiURL + "/draft/api", form);
  return resp;
};
export const getBrouillon = async (type, branch) => {
  const resp = await axios.get(apiURL + `/drafts/${type}/branch/${branch}`);
  return resp.data;
};
export const getBrouillonsApi = async (type, branch) => {
  const resp = await axios.get(apiURL + `/draft/api/${type}/branch/${branch}`);
  return resp.data;
};
export const DeleteBrouillon = async (arrayOfIds) => {
  const res = await axios.delete(`${apiURL}/drafts`, {
    data: { ids: arrayOfIds },
  });
  return res;
};
export const ApiBrouillonDelete = async (arrayOfIds) => {
  const res = await axios.delete(`${apiURL}/drafts/api`, {
    data: { ids: arrayOfIds },
  });
  return res;
};
export const ShopifyAuth = async (shop) => {
  const res = await axios.get(`${apiURL}/shopify/auth`, {
    params: { shop, embedded: "1" },
  });
  return res.data;
};
export const validatePhoneNumber = (phoneNumber) => {
  // Remove spaces and hyphens from the phone number
  const regex = /^(\+?218|218)?(0?9[0-9])\d{7}$|^0(9[0-9])\d{7}$/;
  if (regex.test(phoneNumber)) {
    return true;
  }
  return false;
};
export const ValueHasLetter = (value) => {
  const containsLetters = /[a-zA-Z]/.test(value);

  if (containsLetters) {
    return true;
  }
  return false;
};
export const ShopifyOrders = async (shop, code) => {
  const resp = await axios.get(apiURL + `/api/shopify/orders/${code}/${shop}`);
  return resp;
};
export const getElementOfNumebrVerify = (element) => {
  return element == undefined || ValueHasLetter(element) || element === ""
    ? 0
    : parseFloat(element);
};
export const getElementOfStringVerify = (element) => {
  return element == undefined || element === "" || element == "null"
    ? "Introuvable"
    : element;
};
export const authorize = async (shop) => {
  return encodeURI(
    `https://admin.shopify.com/oauth/install_custom_app?client_id=baeba95ab00a31f28e76d767c87bb02e&signature=eyJfcmFpbHMiOnsibWVzc2FnZSI6ImV5SmxlSEJwY21WelgyRjBJam94TnpBd05ERTJOVGN5TENKd1pYSnRZVzVsYm5SZlpHOXRZV2x1SWpvaU9HVmxZVFF6TG0xNWMyaHZjR2xtZVM1amIyMGlMQ0pqYkdsbGJuUmZhV1FpT2lKaVlXVmlZVGsxWVdJd01HRXpNV1l5T0dVM05tUTNOamRqT0RkaVlqQXlaU0lzSW5CMWNuQnZjMlVpT2lKamRYTjBiMjFmWVhCd0lpd2liV1Z5WTJoaGJuUmZiM0puWVc1cGVtRjBhVzl1WDJsa0lqbzVPVGt4T1RreE5YMD0iLCJleHAiOiIyMDIzLTExLTI2VDE3OjU2OjEyLjk1OVoiLCJwdXIiOm51bGx9fQ%3D%3D--746a519bef351611b94b4173a59a6baba8d1b144`
  );
};

export const redirect = async (code) => {
  console.log(code);
};
export const getOrdersFromShopify = async (access_token, shop) => {
  const resp = await axios.get(
    apiURL + `/api/shopify/orders/${access_token}/${shop}`
  );
  return resp.data;
};
export const capitalizeFirstLetter = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};
export const AuthYoucan = async () => {
  const resp = await axios.get(apiURL + `/youcan/auth`);
  return resp;
};
export const AddSettingGenerale = async (form) => {
  const resp = await axios.post(apiURL + `/setting/general`, form);
  return resp;
};
export const AddParms = async (form) => {
  const resp = await axios.post(apiURL + `/setting/params`, form);
  return resp;
};
export const AddSettingService = async (form, branch) => {
  const resp = await axios.post(apiURL + `/setting/service`, form);
  return resp;
};

export const getOrdersFromYoucan = async (token) => {
  const resp = await axios.get(apiURL + "/api/youcan/orders/" + token);
  return resp.data;
};
export const getAllApiSettings = async (branch) => {
  const resp = await axios.get(`${apiURL}/shops/settings/branch/${branch}`);
  return resp.data;
};
export const getSetting = async (id) => {
  const resp = await axios.get(apiURL + "/settings/" + id);
  return resp.data;
};

export const getErrorMessage = (msg) => {
  Swal.fire({
    title: "Attention",
    position: "center",
    icon: "error",
    text: msg,
    showConfirmButton: true,
    confirmButtonColor: "#2399baff",
    timer: 4500,
  });
};
export const localizationTable = {
  body: {
    emptyDataSourceMessage: (
      <div style={{ textAlign: "center" }}>Aucun enregistrement à afficher</div>
    ),
  },
  pagination: {
    labelRowsSelect: "lignes",
    labelRowsPerPage: "Lignes par page",
  },
  toolbar: {
    searchPlaceholder: "Rechercher",
  },
};

export const getFirstDayOfMonth = () => {
  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  return formatDate(firstDayOfMonth);
};

function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
export const CopyPrefix = (props) => {
  const { openSnack, typeSnack, messageSnack, handleCloseAlert } = props;
  return (
    <Snackbar
      open={openSnack}
      autoHideDuration={3000}
      onClose={handleCloseAlert}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert severity={typeSnack} sx={{ width: "100%" }}>
        {messageSnack}
      </Alert>
    </Snackbar>
  );
};

export const ShopsGroupByDelivrey = (data) => {
  const groupedDeliveries = {};
  data?.forEach((delivery) => {
    if (groupedDeliveries.hasOwnProperty(delivery.id_delivrey)) {
      groupedDeliveries[delivery.id_delivrey].shop_id.push(delivery.shop_id);
    } else {
      groupedDeliveries[delivery.id_delivrey] = {
        ...delivery,
        shop_id: [delivery.shop_id],
      };
    }
  });
  return Object.values(groupedDeliveries);
};

export const DelivreyHasShopOrder = (delivrey, orders) => {
  let hasShop = false;
  if (orders.length > 0) {
    orders.forEach((order) => {
      delivrey.shop_id.forEach((shop) => {
        if (order.id_shop === shop) {
          hasShop = true;
          return;
        }
      });
    });
  }
  return hasShop;
};

export const getFontFamily = () => {
  return {
    NotoNaskhArabic: {
      normal: `${window.location.origin}${Regular}`,
      bold: `${window.location.origin}${Bold}`,
      italics: `${window.location.origin}${Medium}`,
      bolditalics: `${window.location.origin}${SemiBold}`,
    },
  };
};

export const CatchError = () => {
  toast.error("Erreur. Veuillez réessayer.", {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
  });
};
export const CatchErrorMsg = (msg) => {
  toast.error(msg, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
  });
};
export const SettingsOfShopsGroupByDelivrey = (data) => {
  const result = data.reduce((acc, curr) => {
    const existingEntry = acc.find(
      (item) => item.id_delivrey === curr.id_delivrey
    );
    if (existingEntry) {
      const existingShop = existingEntry.shops.find(
        (shop) => shop.id_shop === curr.shop_id
      );
      if (existingShop) {
        existingShop.settings.push({
          name_setting: curr.name_setting,
          active: curr.active,
          column1: curr.column1,
          column2: curr.column2,
          column3: curr.column3,
          column4: curr.column4,
        });
      } else {
        existingEntry.shops.push({
          id_shop: curr.shop_id,
          settings: [
            {
              name_setting: curr.name_setting,
              active: curr.active,
              column1: curr.column1,
              column2: curr.column2,
              column3: curr.column3,
              column4: curr.column4,
            },
          ],
        });
      }
    } else {
      acc.push({
        id_delivrey: curr.id_delivrey,
        name_delivrey: curr.name_delivrey,
        phone: curr.phone,
        password: curr.password,
        active: curr.active,
        agent_delivrey: curr.agent_delivrey,
        platform_id: curr.platform_id,
        label1: curr.label1,
        label2: curr.label2,
        label3: curr.label3,
        branch_id: curr.branch_id,
        shops: [
          {
            id_shop: curr.shop_id,
            settings: [
              {
                name_setting: curr.name_setting,
                active: curr.active,
                column1: curr.column1,
                column2: curr.column2,
                column3: curr.column3,
                column4: curr.column4,
              },
            ],
          },
        ],
      });
    }
    return acc;
  }, []);

  return result;
};
export const RowStyle = () => ({
  color: "black",
  fontWeight: "500",
  fontSize: 12,
  backgroundColor: "white",
});
export const HeaderStyle = () => ({
  backgroundColor: "#1866ee",
  // backgroundColor: "#1a50b5",
  // #2c78e5", "#60aff5"
  // backgroundImage: `linear-gradient(to right,#1a50b5,#2a6ff7)`,
  borderColor: "rgba(255,255,255,0.1)",
  color: "white",
  fontWight: "700",
  fontSize: "13px",
});
export const ReframeDataProducts = (response) => {
  const skuParentSet = new Set(response.map((product) => product.sku_parent));

  const result = Object.values(
    response.reduce(
      (
        acc,
        {
          image,
          ref,
          name_product,
          sale_price,
          name_provider,
          name_brand,
          name_shop,
          receive,
          active,
          synchrone_product,
          display_id,
          sku_parent,
        }
      ) => {
        acc[ref] = acc[ref] || {
          image,
          ref,
          name_product,
          sale_price,
          name_provider,
          name_brand,
          receive,
          active,
          synchrone_product,
          display_id,
          sku_parent,
          name_shop: [],
          parent: false,
        };
        if (!acc[ref].name_shop.includes(name_shop)) {
          acc[ref].name_shop.push(name_shop);
        }
        if (skuParentSet.has(ref)) {
          acc[ref].parent = true;
        }
        return acc;
      },
      {}
    )
  );
  return result;
};

const getCostNawris = async (government, area, delivreyType, retour) => {
  const resp = await axios.get(`${apiURL}/nawris/cost`, {
    params: {
      government,
      area,
      delivreyType,
      retour,
    },
  });
  return resp.data;
};

export const getCostDelivrey = async (BonOrders, delivrey, costsZR, retour) => {
  let cost = {
    value: 0,
  };
  // let delivrey_cost = 0;
  let costOrder = [];
  for (let index = 0; index < BonOrders.length; index++) {
    const item = BonOrders[index];
    if (delivrey.platform_id === 4) {
      await PushCostWithNawris(
        delivrey,
        item,
        item.delivrey_method,
        cost,
        costOrder,
        retour
      );
    }

    // setCostOrder((prev) => [
    //   ...prev,
    //   ,
    // ]);
    // }
  }
  return { costOrder, cost: cost.value };
  // setDelivreyCost(cost);
};

const getApiKey = (delivrey, item, platforme) => {
  const shop = delivrey?.shops?.find((shop) => shop.id_shop === item.id_shop);
  return shop?.settings?.find?.(
    (setting) => setting.name_setting === platforme
  );
};
const PushCostWithNawris = async (
  delivrey,
  item,
  method,
  cost,
  costOrder,
  retour
) => {
  const setting = getApiKey(delivrey, item, "Nawris");
  if (setting) {
    const dataPrice = await getCostNawris(
      item.government,
      item.area,
      method,
      retour
    );
    const delivrey_price = dataPrice.cost;
    cost.value += delivrey_price;
    costOrder.push({
      prefix_order: item.prefix_order,
      cost: delivrey_price,
    });
  } else {
    console.log("Cannot find API token");
  }
};

export const addSubscription = async (paymentDate, deadline) => {
  const resp = await axios.post(`${apiURL}/users/subscription`, {
    paymentDate,
    deadline,
  });
  return resp.data;
};
export const getSubscription = async () => {
  const resp = await axios.get(`${apiURL}/users/subscription`);
  return resp.data;
};
export const FireDelete = (entite) => {
  Swal.fire({
    title: "Valider!",
    text: `${entite}(s) supprimer avec succés`,
    icon: "success",
    showConfirmButton: true,
    confirmButtonColor: "#2399baff",
    timer: 2500,
  });
};
export const getConnectionsByPages = (data) => {
  return data.reduce((acc, current) => {
    const {
      idconnection,
      name_connection,
      accountId,
      token_user,
      branch_id,
      name_page,
      token_page,
    } = current;
    let existingConnection = acc.find(
      (conn) => conn.idconnection === idconnection
    );

    if (existingConnection) {
      existingConnection.pages.push({ name_page, token_page });
    } else {
      acc.push({
        idconnection,
        name_connection,
        accountId,
        token_user,
        branch_id,
        pages: [{ name_page, token_page }],
      });
    }

    return acc;
  }, []);
};
export const customizeTauxProgress = (pourcentage) => {
  return {
    // Customize the root svg element
    root: {},
    // Customize the path, i.e. the "completed progress"
    path: {
      // Path color
      stroke: `rgba(1, 87, 199, ${pourcentage / 100})`,
      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
      strokeLinecap: "butt",
      // Customize transition animation
      transition: "stroke-dashoffset 0.5s ease 0s",
      // Rotate the path
      transform: "rotate(0.25turn)",
      transformOrigin: "center center",
    },
    // Customize the circle behind the path, i.e. the "total progress"
    trail: {
      // Trail color
      stroke: "#d6d6d6",
      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
      strokeLinecap: "butt",
      // Rotate the trail
      transform: "rotate(0.25turn)",
      transformOrigin: "center center",
    },
    // Customize the text
    text: {
      // Text color
      fill: "#f88",
      // Text size
      fontSize: "16px",
    },
    // Customize background - only used when the `background` prop is true
    background: {
      fill: "#d6d6d6",
    },
  };
};
export const ERROR_MESSAGE = "L’opération a échoué";

// LIMIT_ORDER

export const handleErrorLimitOrder = (error) => {
  const path = "LIMIT_ORDER_REACHED";
  const msg = "Vous avez atteint la limite maximale de commandes.";
  handleErrorSubscription(error, path, msg);
};
export const handleErrorLimitUser = (error) => {
  const path = "LIMIT_USER_REACHED";
  const msg = "Vous avez atteint la limite maximale de utilisateurs.";
  handleErrorSubscription(error, path, msg);
};
export const handleErrorLimitDelivrey = (error) => {
  const path = "LIMIT_DELIVREY_REACHED";
  const msg = "Vous avez atteint la limite maximale de livreurs.";
  handleErrorSubscription(error, path, msg);
};
const handleErrorSubscription = (error, path, msg) => {
  if (
    error.response &&
    error.response.status === 403 &&
    error.response.data.error === path
  ) {
    Swal.fire({
      title: "Attention",
      position: "center",
      icon: "error",
      text: msg,
      showConfirmButton: true,
      confirmButtonColor: "#2399baff",
    });
    return;
  }
};

import React, { useContext, useEffect, useMemo, useState } from "react";

import "react-toastify/dist/ReactToastify.css";

import {
  ApiBrouillonDelete,
  getAllShops,
  getAllCustomers,
  getAllProducts,
  getBrouillonsApi,
  ShopifyAuth,
  getAllSalesPlatforme,
} from "../../../setup/services";
import ColumnsWoo from "../../../components/Columns/ColumnsWoo";
import { TypeContext } from "App";
import Title from "components/Title";
import useAuth from "hooks/useAuth";
import ApiBrouillonUpdate from "components/Shopify/ApiBrouillonUpdate";
import { ToastContainer, toast } from "react-toastify";
import { ListBrouillons } from "setup/ApiHelpe";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import CustomBox from "components/CustomBox";
import HeaderWoocommerce from "components/Woocommerce/HeaderWoocommerce";
import TableWoocommerce from "components/Woocommerce/TableWoocommerce";
import DialogShop from "components/Woocommerce/DialogShop";
import AffectationWoocommerce from "components/Woocommerce/AffectationWoocommerce";
import MenuWoocommerce from "components/Woocommerce/MenuWoocommerce";
import { useTranslation } from "react-i18next";

const WoocommerceListe = () => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [t] = useTranslation("global");
  const location = useLocation();
  const [domaineParams, setDomaineParams] = useState(null);

  const searchParams = new URLSearchParams(location.search);

  const [, setChangeType] = useContext(TypeContext);
  const [boutiques, setBoutiques] = useState([]);
  const [update, setUpdate] = useState(false);

  const [modalAffectation, setModalAffectation] = useState(false);
  const handleOpenAffectation = () => setModalAffectation(true);
  const handleCloseAffectation = () => setModalAffectation(false);
  const [Wilayas, setWilayas] = useState([]);
  const [Communes, setCommunes] = useState([]);
  const [produits, setProduits] = useState([]);
  const [countSelected, setCountSelected] = useState([]);
  const [SelectedCmd, setSelectedCmd] = useState([]);
  const colCommandes = useMemo(() => ColumnsWoo(t), [t]);
  const [openModelUpdate, setOpenModelUpdate] = useState(false);
  const handleOpenModelUpdate = () => setOpenModelUpdate(true);
  const handleCloseModelUpdate = () => setOpenModelUpdate(false);
  const [commandesValide, setCommandesValide] = useState([]);
  const [brouillons, setBrouillons] = useState([]);
  const [clients, setClients] = useState([]);
  const [success, setSuccess] = useState(false);
  const [loadedShop, setLoadedShop] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  // const [openModelList, setOpenModelList] = useState(false);
  const [domaine, setDomaine] = useState("");
  const [domaines, setDomaines] = useState([]);
  const [modalDomaine, setModalDomaine] = useState(false);
  const handleOpenMenu = () => setModalDomaine(true);
  const handleCloseMenu = () => () => setModalDomaine(false);

  const handleCloseModel = () => setOpenModel(false);

  const handleOpenModel = () => setOpenModel(true);
  // const handleCloseModelList = () => {
  //   navigate("/commandes/create/shopify");
  //   setOpenModelList(false);
  // };
  // const handleOpenModelList = () => setOpenModelList(true);
  // authorize("8eea43").then((resp) => {
  const defaultPropsBoutique = {
    options: boutiques || [],
    getOptionLabel: (option) => option.name_shop,
  };
  const gotError = (error) => {
    Swal.fire({
      title: "Attention !",
      text: error,
      showConfirmButton: false,
      icon: "error",
      timer: 2000,
    }).then(() => {
      setLoaded(false);
      setLoaded(false);
    });
  };
  useEffect(() => {
    const forbiddenParams = searchParams.get("forbidden");
    const alreadyExistsParam = searchParams.get("already_exists");
    const shopParams = searchParams.get("shop");
    if (forbiddenParams !== null) {
      gotError("Le domaine de shopify n'est pas corrects !");
    }

    if (alreadyExistsParam !== null) {
      gotError("Le domaine de shopify existe déjà !");
      navigate("/commandes/create/shopify");
    }
    if (shopParams !== null) {
      setDomaineParams(shopParams);
      handleOpenAffectation();
    }
    getBrouillonsApi(3, auth.branch).then((data) => {
      const brouillons = ListBrouillons(data);
      setBrouillons(brouillons);
    });
    getAllProducts(auth.branch).then((data) => {
      setProduits(data);
    });
    getAllCustomers(undefined, auth.branch).then((data) => {
      setClients(data);
    });
    getAllSalesPlatforme(auth.branch, "WooCommerce").then((data) => {
      setDomaines(data);
    });
    getAllShops(auth.branch).then((data) => {
      setBoutiques(data);
    });

    // .finally(() => {
    // handleOpenModelList();
    // });
  }, [update, auth.branch]);

  // useEffect(() => {
  //   if (success && commandesValide.length > 0) {
  //     // commande length- comdBreuill
  //     toast.success("Commande créer avec succés", {
  //       ...ToastStatus,
  //       onClose: () => {
  //         setCommandes([]);
  //         setSuccess(false);
  //         setCommandesValide([]);
  //         setChangeType((prev) => !prev);
  //         setUpdate((prev) => !prev);
  //         setCountSelected([]);
  //       },
  //     });
  //   }
  // }, [success]);
  // const remove = (ref) => {
  //   DeleteListCmd(ref);
  //   setUpdate((prev) => !prev);
  // };

  const validateSuppression = (ids) => {
    if (ids.length > 0) {
      const alert =
        ids.length === 1
          ? `${t("Vous voulez vraiment supprimer")} ${t("le")} ${t(
              "brouillon"
            )} #${ids[0]} ?`
          : `${t("Vous voulez vraiment supprimer")} ${t("les")} ${
              ids.length
            } ${t("brouillons")} `;
      const confirmed = window.confirm(alert);
      if (confirmed) {
        ApiBrouillonDelete(ids).then(() => {
          setUpdate((prev) => !prev);
          setChangeType((prev) => !prev);
          setCountSelected([]);
        });
      }
    }
  };
  // const Synchroniser = () => {
  //   setLoaded(true);
  //   UpdateOrdersShopify(auth.branch)
  //     .then((data) => {
  //       const drafts = ListBrouillons(data);
  //       setBrouillons(drafts);
  //       toast.success("Shopify commande(s) a été synchroniser", {
  //         ...ToastStatus,
  //       });
  //     })
  //     .finally(() => {
  //       setLoaded(false);
  //       setChangeType((prev) => !prev);
  //     });
  // };
  const sendDomaine = () => {
    setLoadedShop(true);
    const form = new FormData();
    // form.append("id", boutiqueSelected?.id_shop);
    form.append("domaine_shopify", domaine);
    form.append("branch", auth.branch);
    form.append("checked_shopify", 1);
    // form.append("shop", boutiquesSelected.id_shop);
    form.append("type", 2);
    // const obj = Object.fromEntries(form);
    ShopifyAuth(domaine).then((data) => {
      const queryString = new URLSearchParams(data);

      // Extract parameters
      const redirectUri = decodeURIComponent(queryString.get("redirectUri"));
      if (redirectUri) {
        window.location.href = redirectUri;
      } else {
        toast.error(
          t("Erreur: Impossible de se connecter . Veuillez réessayer.")
        );
        setLoadedShop(false);
      }
    });
    // UpdateShopApiElement(obj).then(() => {
    //   window.location.href = `https://${domaine}/admin/oauth/authorize?client_id=${process.env.REACT_APP_SHOPIFY_CLIENT_ID}&scope=read_orders,write_orders,read_customers,write_customers,read_products,write_products,read_shipping,write_shipping,read_shopify_payments_payouts,read_assigned_fulfillment_orders&redirect_uri=${process.env.REACT_APP_API_URL}/shopify/redirect`;
    // });
  };

  return (
    <>
      <ToastContainer />

      <Title title={t("Commandes WooCommerce")} />
      <CustomBox title={t("Importez vos commandes avec WooCommerce")} />
      <MenuWoocommerce
        items={domaines}
        toggleDrawer={handleCloseMenu}
        defaultPropsBoutique={defaultPropsBoutique}
        open={modalDomaine}
        setDomaines={setDomaines}
      />
      <DialogShop
        handleClose={handleCloseModel}
        open={openModel}
        sendDomaine={sendDomaine}
        loaded={loadedShop}
        success={success}
        auth={auth}
        defaultPropsBoutique={defaultPropsBoutique}
        domaine={domaine}
        setDomaine={setDomaine}
        t={t}
      />

      <AffectationWoocommerce
        branch={auth.branch}
        domaine={domaineParams}
        open={modalAffectation}
        handleClose={handleCloseAffectation}
        shops={boutiques}
        setDomaines={setDomaines}
        platforme="woocommerce"
        t={t}
      />

      {SelectedCmd && SelectedCmd.length > 0 && countSelected.length > 0 && (
        <ApiBrouillonUpdate
          form={SelectedCmd[0]}
          setUpdate={setUpdate}
          open={openModelUpdate}
          handleClose={handleCloseModelUpdate}
          shops={boutiques}
          customers={clients}
          productsDB={produits}
          setCities={setCommunes}
          setChangeType={setChangeType}
          setSuccess={setSuccess}
          setOrdersValide={setCommandesValide}
          auth={auth}
        />
      )}
      <HeaderWoocommerce
        countSelected={countSelected}
        handleOpenModel={handleOpenModel}
        handleOpenModelUpdate={handleOpenModelUpdate}
        validateSuppression={validateSuppression}
        // Synchroniser={Synchroniser}
        handleMenu={handleOpenMenu}
        t={t}
      />
      {/* <ListeDomaines
        DeleteSetting={DeleteSetting}
        // UpdateSetting={UpdateSetting}
        defaultPropsBoutique={defaultPropsBoutique}
        items={domaines}
      /> */}
      <TableWoocommerce
        brouillons={brouillons}
        colCommandes={colCommandes}
        countSelected={countSelected}
        loaded={loaded}
        setCountSelected={setCountSelected}
        setSelectedCmd={setSelectedCmd}
      />
    </>
  );
};

export default WoocommerceListe;

import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import notFoundImage from "../../../assets/images/not-found.png";
import { FiChevronLeft, FiChevronRight, FiPlus } from "react-icons/fi";
import {
  CatchError,
  PostOrder,
  getAllCustomers,
  getAllShops,
  getCities,
  handleErrorLimitOrder,
} from "../../../setup/services";
import InsertProduct from "../../../components/ِCommand/InsertProduct";
import { FaWpforms } from "react-icons/fa";
import Swal from "sweetalert2";
import { TypeContext } from "App";
import useAuth from "hooks/useAuth";
import Title from "components/Title";
import ButtonLoading from "components/ButtonLoading";

import Ajouter from "pages/Client/Ajouter/Ajouter";
import CustomBox from "components/CustomBox";
import { useTranslation } from "react-i18next";
const AjouterCommande = () => {
  const [, setChangeType] = useContext(TypeContext);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    delivrey: 0,
    reduction: 0,
    referent: "",
    note: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phone: "",
    id_shop: "",
    id_customer: "",
  });
  const [t, i18n] = useTranslation("global");
  const [addCustomer, setAddCustomer] = useState(false);
  const [semiTotale, setSemiTotale] = useState(0);
  const [Totale, setTotale] = useState(0);
  const [methodDelivrey, setMethodeLivraison] = useState(0);
  const [TypeOrder, setTypeOrder] = useState(0);
  const [shops, setShops] = useState([]);
  const [shopsSelected, setShopsSelected] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [customersSelected, setCustomersSelected] = useState(null);
  const [disabledInputs, setDisabledInputs] = useState(false);
  const [openModelCustomer, setOpenModelCustomer] = useState(false);
  const [disabledCommune, setDisabledCommune] = useState(false);
  const [ButtonDisable, setButtonDisable] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [loaded, setLoaded] = useState(false);
  const { name, address, phone, email, delivrey, reduction, referent, note } =
    formData;

  const [cart, setCart] = useState([]);
  const { auth } = useAuth();

  const defaultPropsClients = {
    options: customers,
    getOptionLabel: (option) => option.name_customer,
  };
  const defaultProps = {
    options: shops,
    getOptionLabel: (option) => option.name_shop,
  };

  const addQte = (ref) => {
    setCart((prevProducts) => {
      return prevProducts.map((product) => {
        return product.ref === ref
          ? { ...product, qty: parseInt(product.qty) + 1 }
          : product;
      });
    });
  };
  const removeQte = (ref) => {
    setCart((prevProducts) => {
      return prevProducts.map((product) => {
        return product.ref === ref && product.qty > 1
          ? { ...product, qty: product.qty - 1 }
          : product;
      });
    });
  };
  const remove = (ref) => {
    setCart((prevProducts) => {
      return prevProducts.filter((product) => product.ref != ref);
    });
  };
  useEffect(() => {
    if (auth.branch === undefined) {
      return;
    }
    getAllShops(auth.branch).then((values) => {
      setShops(values);
    });
    if (cart && cart.length > 0) {
      let montant = 0;
      cart.forEach((element) => {
        montant += element.price * element.qty;
      });
      setSemiTotale(montant);
      setTotale(montant + delivrey - reduction);
    } else {
      setSemiTotale(0);
      setTotale(delivrey - reduction);
    }
  }, [cart, shopsSelected, methodDelivrey]);

  useEffect(() => {
    if (
      customersSelected?.id_customer &&
      customersSelected?.id_customer !== 0
    ) {
      setFormData((prevState) => ({
        ...prevState,
        phone: customersSelected.phone,
        email: customersSelected.email,
        address: customersSelected.address,
      }));
    } else {
      setDisabledInputs(false);
      setFormData((prev) => ({
        ...prev,
        phone: "",
        email: "",
        address: "",
      }));
    }
  }, [customersSelected]);
  const SendValue = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };
  const SendValueFrais = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.id]: parseInt(e.target.value),
    }));
  };
  const ErrorPanier = () => {
    Swal.fire({
      position: "center",
      icon: "warning",
      title: "Panier vide !",
      text: t("Vous devez ajouter au moins un produit dans le panier"),
      showConfirmButton: true,
      confirmButtonColor: "#2399baff",
      timer: 1500,
    });
  };
  const MessageValidate = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Valider !",
      text: t("Commande créée avec succès"),
      showConfirmButton: true,
      confirmButtonColor: "#2399baff",
      timer: 1500,
    });
  };
  const SendSubmit = () => {
    if (cart.length > 0) {
      SendForm();
    } else {
      ErrorPanier();
    }
  };
  const SendForm = () => {
    setLoaded(true);
    setFormErrors({
      name: "",
      email: "",
      phone: "",
      province: "",
      city: "",
      id_shop: "",
      id_customer: "",
    });
    const formDataFile = new FormData();
    formDataFile.append("id_customer", customersSelected?.id_customer);
    formDataFile.append("branch", auth.branch);
    formDataFile.append("id_shop", shopsSelected?.id_shop);
    formDataFile.append("prefix", shopsSelected?.prefix);
    formDataFile.append("type", TypeOrder);
    formDataFile.append("methode", methodDelivrey);
    formDataFile.append("note", note === undefined ? "" : note);
    formDataFile.append("referent", referent);
    formDataFile.append("delivrey", !delivrey ? 0 : delivrey);
    formDataFile.append("reduction", !reduction ? 0 : reduction);
    formDataFile.append("cart", JSON.stringify(cart));
    formDataFile.append("name", name);
    formDataFile.append("phone", phone);
    formDataFile.append("email", email);
    formDataFile.append("address", address);
    formDataFile.append("action", 1);
    formDataFile.append("user", auth?.idUser);
    const obj = Object.fromEntries(formDataFile);
    PostOrder(obj)
      .then(() => {
        // PostHistoricOrder(1, auth?.idUser, "-", shopsSelected?.prefix).then(
        //   () => {
        setChangeType((prev) => !prev);
        MessageValidate();
        setFormData({
          name: "",
          phone: "",
          email: "",
          address: "",
          delivrey: 0,
          reduction: 0,
          referent: "",
          note: "",
        });
        setShopsSelected(null);
        setCustomersSelected(null);
        setCart([]);
      })
      .catch((error) => {
        handleErrorLimitOrder(error);
        if (error?.response?.data?.errors?.length > 0) {
          error.response.data.errors.forEach((objet) => {
            if (formErrors.hasOwnProperty(objet.path)) {
              setFormErrors((prev) => ({
                ...prev,
                [objet.path]: objet.msg,
              }));
            }
          });
        } else {
          CatchError();
        }
      })
      .finally(() => {
        setLoaded(false);
      });
  };
  useEffect(() => {
    if (!auth.branch) {
      return;
    }

    // (async () => {
    // await sleep(1e3);

    // if (active) {
    getAllCustomers(undefined, auth.branch).then((data) => {
      if (data.length > 0) {
        setCustomers(data);
      }
    });
    // }
    // })();

    // return () => {
    //   active = false;
    // };
  }, [auth.branch, addCustomer]);
  // useEffect(() => {
  //   if (!open) {
  //     setCustomers([]);
  //   }
  // }, [open]);
  const handleOpenCustomer = () => setOpenModelCustomer(true);
  const handleCloseCustomer = () => setOpenModelCustomer(false);
  return (
    <Box width={"75%"} marginX={"auto"}>
      <Title title={t("Passer une commande")} />
      <CustomBox title={t("Passer une commande")} />
      <Box component={Paper} elevation={3} padding={2} borderRadius={2}>
        <div className="header_addCmd-d_flex" style={{ marginTop: 10 }}>
          <div
            style={{
              backgroundColor: "#2399baff",
              borderRadius: "50%",
              width: 30,
              height: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="pickup-title"
          >
            <FaWpforms color="white" fontSize={20} />
          </div>
          <p>
            {t("Par")} {t("Formulaire")}
          </p>
        </div>

        <div action="" className="form">
          <div className="select-d-flex">
            <Autocomplete
              {...defaultProps}
              className="auto-complete"
              id="shops"
              error={true}
              isOptionEqualToValue={(option, value) =>
                option.id_shop === value.id_shop
              }
              onChange={(event, newValue) => {
                setShopsSelected(newValue);
              }}
              value={shopsSelected}
              renderInput={(params) => (
                <TextField
                  error={formErrors.id_shop === "" ? false : true}
                  helperText={
                    formErrors.id_shop === "" ? false : formErrors.id_shop
                  }
                  {...params}
                  label={t("Boutiques")}
                  variant="outlined"
                  value={shopsSelected}
                />
              )}
            />
            <FormControl variant="outlined" className="auto-complete">
              <InputLabel id="label-methode">
                {t("Méthode de livraison")}
              </InputLabel>
              <Select
                labelId="label-methode"
                name="select_methode"
                id="methode"
                //   value={age}
                onChange={(e) => setMethodeLivraison(parseInt(e.target.value))}
                label={t("Méthode de livraison")}
                value={methodDelivrey}
              >
                <MenuItem value={0}>{t("Livraison à domicile")}</MenuItem>
                <MenuItem value={1}>{t("Stop desk")}</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Box>
            <Autocomplete
              {...defaultPropsClients}
              className="auto-complete"
              id="customers"
              isOptionEqualToValue={(option, value) =>
                option.id_customer === value.id_customer
              }
              // open={open}
              // onOpen={() => {
              //   setOpen(true);
              // }}
              // onClose={() => {
              //   setOpen(false);
              // }}
              error={true}
              onChange={(event, newValue) => {
                setCustomersSelected(newValue);
              }}
              sx={{ width: "98%" }}
              noOptionsText={
                <Button onClick={handleOpenCustomer} variant="contained">
                  {t("Ajouter")}
                </Button>
              }
              value={customersSelected}
              renderInput={(params) => (
                <TextField
                  error={formErrors.id_customer === "" ? false : true}
                  helperText={
                    formErrors.id_customer === ""
                      ? false
                      : formErrors.id_customer
                  }
                  {...params}
                  label={t("Client")}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    // endAdornment: (
                    //   <React.Fragment>
                    //     {customersSelected === null ? (
                    //       <CircularProgress color="inherit" size={20} />
                    //     ) : null}
                    //     {params.InputProps.endAdornment}
                    //   </React.Fragment>
                    // ),
                  }}
                />
              )}
            />
          </Box>
          {customersSelected && customersSelected.id_customer === 0 && (
            <TextField
              error={formErrors.name === "" ? false : true}
              helperText={formErrors.name === "" ? false : formErrors.name}
              id="name"
              value={name === null ? "" : name}
              label={t("Nom")}
              className="label_txtField select"
              onChange={(e) => SendValue(e)}
              variant="outlined"
            />
          )}
          <div className="select-d-flex">
            <TextField
              disabled={disabledInputs}
              error={formErrors.phone === "" ? false : true}
              helperText={formErrors.phone === "" ? false : formErrors.phone}
              id="phone"
              label={t("Téléphone")}
              value={phone === null ? "" : phone}
              className="label_txtField select"
              variant="outlined"
              onChange={(e) => SendValue(e)}
            />
            <TextField
              disabled={disabledInputs}
              error={formErrors.email === "" ? false : true}
              helperText={formErrors.email === "" ? false : formErrors.email}
              id="email"
              value={email === null ? "" : email}
              label={`${t("Email")}/ ${t("Téléphone")} 2`}
              className="label_txtField select"
              onChange={(e) => SendValue(e)}
              variant="outlined"
            />
          </div>
          <TextField
            disabled={disabledInputs}
            id="address"
            className="label_txtField"
            label={t("Adresse")}
            variant="outlined"
            onChange={(e) => SendValue(e)}
            value={address === null ? "" : address}
          />
          <div className="select-d-flex">
            <TextField
              id="note"
              label={t("Remarques")}
              className="label_txtField select"
              onChange={(e) => SendValue(e)}
              variant="outlined"
            />
            <TextField
              id="referent"
              label={`${t("Réfèrent")} : (Facebook,instagram...)`}
              className="label_txtField select"
              onChange={(e) => SendValue(e)}
              variant="outlined"
            />
          </div>
          <div className="shop_list">
            <InsertProduct auth={auth} cart={cart} setCart={setCart} />
          </div>
          <div className="panier_values">
            {cart &&
              cart.length > 0 &&
              cart.map((product) => {
                const imageHtml = product.image
                  ? `${process.env.REACT_APP_API_URL}/public/images/${product.image}`
                  : notFoundImage;
                return (
                  <div
                    className={
                      product.error
                        ? "panier_product bg-error"
                        : "panier_product"
                    }
                  >
                    <img
                      src={imageHtml}
                      alt={product.name}
                      width={80}
                      height={65}
                      style={{ borderRadius: 15, fontSize: 12 }}
                    />
                    <div className="panier-produit_title">{product.name}</div>
                    <div className="panier-produit_prix">
                      {product.price} {t(auth?.devise) || ""}
                    </div>
                    <div className="panier-produit_qte">
                      <CloseIcon style={{ width: 15 }} /> {product.qty}
                    </div>
                    <div className="card-qte">
                      <FiChevronRight
                        onClick={() => addQte(product.ref)}
                        className="chevron right__icon"
                      />
                      <small>{product.qty}</small>
                      <FiChevronLeft
                        onClick={() => removeQte(product.ref)}
                        className="chevron left__icon"
                      />
                    </div>
                    <IconButton
                      color="error"
                      onClick={() => remove(product.ref)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                );
              })}
          </div>
          <div className="panier_result">
            <div className="panier-sous_result">
              <div className="panier_result-title">{t("Sous Totale")} :</div>{" "}
              <div className="panier_result-infor padding-r">
                {semiTotale} {t(auth?.devise) || ""}
              </div>{" "}
            </div>

            <div className="panier-sous_result">
              <div className="panier_result-title">{t("Livraison")} :</div>{" "}
              <div className="panier_result-infor">
                {" "}
                <TextField
                  id="delivrey"
                  className="label_txtField input_panier"
                  type="number"
                  disabled={ButtonDisable}
                  value={delivrey}
                  onChange={(e) => SendValueFrais(e)}
                  inputProps={{ min: "0" }}
                />
              </div>{" "}
            </div>
            <div className="panier-sous_result">
              <div className="panier_result-title">{t("Réduction")} :</div>{" "}
              <div className="panier_result-infor">
                {" "}
                <TextField
                  onChange={(e) => SendValueFrais(e)}
                  id="reduction"
                  className="label_txtField input_panier"
                  type="number"
                  value={reduction}
                  inputProps={{ min: "0" }}
                />
              </div>{" "}
            </div>

            <div className="panier-sous_result">
              <div className="panier_result-title">{t("Totale")} :</div>{" "}
              <div className="panier_result-infor padding-r">
                {semiTotale +
                  (!delivrey ? 0 : delivrey) -
                  (!reduction ? 0 : reduction)}{" "}
                {t(auth?.devise) || ""}
              </div>{" "}
            </div>
          </div>
          <div className="commande-radio ">
            <div>
              <input
                id={"radio-1"}
                type="radio"
                value={0}
                checked={TypeOrder == 0 ? true : false}
                onChange={(e) => setTypeOrder(0)}
              />
              <label htmlFor={"radio-1"} className="label-radio">
                {t("En attente de confirmation")}
              </label>
            </div>
            <div>
              <input
                checked={TypeOrder == 0 ? false : true}
                onChange={(e) => setTypeOrder(1)}
                id={"radio-2"}
                type="radio"
                value={1}
              />
              <label htmlFor={"radio-2"} className="label-radio">
                {t("Confirmée")}
              </label>
            </div>
          </div>
          <div className="btn-add_produit">
            {loaded ? (
              <ButtonLoading />
            ) : (
              <Button
                variant="contained"
                startIcon={<FiPlus />}
                onClick={SendSubmit}
              >
                {t("Finaliser la commande")}
              </Button>
            )}
          </div>
        </div>
        <Dialog
          open={openModelCustomer}
          fullWidth
          onClose={handleCloseCustomer}
        >
          <Box margin={4}>
            <Ajouter isDialog setIsInsert={setAddCustomer} />
          </Box>
        </Dialog>
      </Box>
    </Box>
  );
};

export default AjouterCommande;
